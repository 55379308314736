import React, {createContext, useReducer} from 'react';

const initialState = {
		theme: 'dark',
        roleValue: 0,
		authUser: undefined,
		news: undefined,
		members: [],
		allMembers: [],
		editCar: undefined,
		editNews: undefined,
		selectedEmail: [],
		searchFilter: {searchText: "", tags: [], payment: "Alla", hasEmail: ""}
};

const store = createContext(initialState);
const { Provider } = store;
const currentYear = new Date().toISOString().substring(0, 4);

const StateProvider = ( { children } ) => {
	const [state, dispatch] = useReducer((state, action) => {
		switch(action.type) {
			case 'TOGGLE_THEME':
				const newTheme = state.theme === 'light' ? 'dark' : 'light'
				return {...state, theme: newTheme}
			case 'SET_AUTHUSER':
				return {...state, authUser: action.value, roleValue: action.roleVal}
			case 'SET_NEWS':
				return {...state, news: action.value}
			case 'SET_ALLMEMBERS':
				return {...state, allMembers: [...action.value], members: [...action.value]}
			case 'SET_MEMBERS':
				return {...state, members: [...action.value]}
			case 'SET_EDITCAR':
				return {...state, editCar: action.value}
            case 'SET_EDITNEWS':
                return {...state, editNews: action.value}
            case 'SET_SELECTEDEMAIL':
				return {...state, selectedEmail: [...action.value]}
			case 'SET_SEARCHFILTER':
                let filterMembers = state.allMembers;
                switch (action.value.hasEmail) {
                    case "All email":
                        filterMembers = filterMembers.filter(fm => {
                            if(fm.Email !== undefined && fm.Email !== "") {
                                return fm;
                            } else {
                                return false;
                            }
                        });
                        break;
                    case "Enbart post":
                        filterMembers = filterMembers.filter(fm => {
                            if(fm.Email === undefined || fm.Email === "") {
                                return fm;
                            } else {
                                return false;
                            }
                        });
                        break;
                
                    default:
                        break;
                }

                switch (action.value.payment) {
                    case "Betalda":
                        filterMembers = filterMembers.filter(fm => {
                            if(fm.Payments) {
                                let payedThisYear = fm.Payments.filter(p => p.PayYear === currentYear);
                                if(payedThisYear.length > 0) {
                                    return fm;
                                } else {
                                    return false;
                                }
                            } else {
                                return false;
                            }
                        });
                        break;
                    case "Ej betalda":
                        filterMembers = filterMembers.filter(fm => {
                            if(fm.Payments ) {
                                let payedThisYear = fm.Payments.filter(p => p.PayYear === currentYear);
                                if(payedThisYear <= 0) {
                                    return fm;
                                } else {
                                    return false;
                                }
                            } else {
                                return fm;
                            }
                        });
                        break;
                    default:
                        break;
                }

                action.value.tags.forEach(tag => {
                    filterMembers = filterMembers.filter(fm => {
                        if(fm.Tagging.includes(tag)) {
                            return fm;
                        } else {
                            return false;
                        }
                    });
                });

                if(action.value.searchText !== "") {
                    filterMembers = filterMembers.filter(m => {
                        if(m.FirstName.toLowerCase().indexOf(action.value.searchText) !== -1 || m.LastName.toLowerCase().indexOf(action.value.searchText) !== -1 ) {
                            return m;
                        } else {
                            return false;
                        }
                    });
                }
                return {...state, searchFilter: action.value, members: filterMembers}
					 
			default:
				return state;
		};
	}, initialState);

	return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider, currentYear }