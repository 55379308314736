/* eslint-disable react-hooks/exhaustive-deps */
import { Nav, NavItem, FormControl, Row, Modal, Col, Form } from "react-bootstrap";
import { store } from "../services/store";
import { useContext, useEffect, useState } from "react";
import { sortItems, availableTaggings } from "../services/appFunctions";
import { Multiselect } from 'multiselect-react-dropdown';


const FilterMembers = (props) => {
    const globalStore = useContext(store);
    const { dispatch } = globalStore;
    const [showFilterModal, setShowFilterModal] = useState(false);


	const clearFilter = () => {
		let updSearchFilter = {searchText: "", tags: [], payment: "Alla", hasEmail: ""};
		const action = { type: 'SET_SEARCHFILTER', value: updSearchFilter};
		dispatch(action);
	}


    return (
        <>
            <Nav>
                <NavItem>
                    <Nav.Link>
                        {globalStore.state.members.length}
                    </Nav.Link>
                </NavItem>
                <NavItem>
                    <Nav.Link onClick={() => setShowFilterModal(true)}>
                        Filter
                    </Nav.Link>
                </NavItem>
                <NavItem>
                    <i 
                        className="bi bi-x-octagon clearFilterIcon" 
                        onClick={clearFilter}
                        title="Rensa all filter">
                    </i>
                </NavItem>
                <FormControl
                    type="search"
                    autoFocus={true}
                    placeholder="Sök"
                    aria-label="Search"
                    style={{margin: "0 20px"}}
                    value={globalStore.state.searchFilter.searchText}
                    onChange={(event => {
                        let updSearchFilter = globalStore.state.searchFilter;
                        updSearchFilter.searchText = (event.target.value ? event.target.value : "").toLowerCase();
                        const action = { type: 'SET_SEARCHFILTER', value: updSearchFilter};
                        dispatch(action);
                    })}
                />
            </Nav>
            
            
                <Modal 
                show={showFilterModal} 
                onHide={() => {setShowFilterModal(false)}}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Filter</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{margin: "50px"}}>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Betalningar</Form.Label>
                                <Form.Select
                                    value={globalStore.state.searchFilter.payment} 
                                    onChange={event => {
                                        let updSearchFilter = globalStore.state.searchFilter;
                                        updSearchFilter.payment = event.target.value;
                                        const action = { type: 'SET_SEARCHFILTER', value: updSearchFilter};
                                        dispatch(action);
                                    }}
                                >
                                    <option>Alla</option>
                                    <option>Betalda</option>
                                    <option>Ej betalda</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Email</Form.Label>
                                <Form.Select
                                    value={globalStore.state.searchFilter.hasEmail} 
                                    onChange={event => {
                                        let updSearchFilter = globalStore.state.searchFilter;
                                        updSearchFilter.hasEmail = event.target.value;
                                        const action = { type: 'SET_SEARCHFILTER', value: updSearchFilter};
                                        dispatch(action);
                                    }}
                                >
                                    <option></option>
                                    <option>All email</option>
                                    <option>Enbart post</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row style={{margin: "20px 0"}}>
                        <Col>
                            <Form.Label>Taggning</Form.Label>
                            <Multiselect
                                options={availableTaggings}
                                name="Tagging"
                                placeholder="Filter på tags"
                                isObject={false}
                                selectedValues={globalStore.state.searchFilter.tags}
                                onSelect={(list, item) => {
                                    let updSearchFilter = globalStore.state.searchFilter;
                                    updSearchFilter.tags = list;
                                    const action = { type: 'SET_SEARCHFILTER', value: updSearchFilter};
                                    dispatch(action);
                                }}
                                onRemove={(list, item) => {
                                    let updSearchFilter = globalStore.state.searchFilter;
                                    updSearchFilter.tags = list;
                                    const action = { type: 'SET_SEARCHFILTER', value: updSearchFilter};
                                    dispatch(action);
                                }}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default FilterMembers;
