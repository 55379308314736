import { Container, Form, Button, Row, Col, FloatingLabel } from "react-bootstrap";
import { useState, useContext, useRef } from "react";
import { store } from "../services/store";
import { addMember } from "../services/firebase";
import { useNavigate } from 'react-router-dom';
import { Multiselect } from 'multiselect-react-dropdown';
import {availableTaggings} from "../services/appFunctions";

const AddMember = () => {
    const [inputs, setInputs] = useState({StartDate: new Date().toISOString().substring(0, 10)});
    const globalStore = useContext(store);
    const formRef = useRef(null);
    const formTextareaRef = useRef(null);
    const [tagging, setTagging] = useState([]);
    const navigate = useNavigate();

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value ? event.target.value : "";
        setInputs(values => ({...values, [name]: value}))
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        let addMemberObj = inputs;
        const today = new Date();
        addMemberObj.Created = today.toISOString();
        addMemberObj.CreatedBy = globalStore.state.authUser.email;
        addMemberObj.Tagging = tagging;
        addMember(addMemberObj);
        setInputs({StartDate: new Date()});
        setTagging([]);
        formRef.current.reset();
        formTextareaRef.current.value = "";
        navigate("/members");
    }

    return (
        <Container className="ligthArea">
             <Form onSubmit={handleSubmit} ref={formRef}>
                <Row className="mb-3">
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Förnamn"
                        as={Col}
                    >
                        <Form.Control
                            type="text"
                            placeholder="FirstName"
                            value={inputs.FirstName}
                            name="FirstName"
                            onChange={handleChange}
                            autoFocus={true}
                        />
                    </FloatingLabel>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Efternamn"
                        as={Col}
                    >
                        <Form.Control
                            type="text"
                            placeholder="Efternamn"
                            value={inputs.LastName}
                            name="LastName"
                            onChange={handleChange}
                        />
                    </FloatingLabel>
                </Row>
                <Row className="mb-3">
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Adress"
                        as={Col}
                    >
                        <Form.Control
                            type="text"
                            placeholder="Adress"
                            value={inputs.Address}
                            name="Address"
                            onChange={handleChange}
                        />
                    </FloatingLabel>

                </Row>
                <Row className="mb-3">
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Postnummer"
                        as={Col}
                    >
                        <Form.Control
                            type="text"
                            placeholder="Postnummer"
                            value={inputs.Zip}
                            name="Zip"
                            onChange={handleChange}
                        />
                    </FloatingLabel>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Ort"
                        as={Col}
                    >
                        <Form.Control
                            type="text"
                            placeholder="Ort"
                            value={inputs.City}
                            name="City"
                            onChange={handleChange}
                        />
                    </FloatingLabel>
                </Row>
                <Row className="mb-3">
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Telefonnummer (Hem)"
                        as={Col}
                    >
                        <Form.Control
                            type="text"
                            placeholder="Hem"
                            value={inputs.PhoneHome}
                            name="PhoneHome"
                            onChange={handleChange}
                        />
                    </FloatingLabel>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Telefonnummer (Mobil)"
                        as={Col}
                    >
                        <Form.Control
                            type="text"
                            placeholder="Mobil"
                            value={inputs.PhoneMobil}
                            name="PhoneMobil"
                            onChange={handleChange}
                        />
                    </FloatingLabel>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Telefonnummer (Arbete)"
                        as={Col}
                    >
                        <Form.Control
                            type="text"
                            placeholder="Arbete"
                            value={inputs.PhoneWork}
                            name="PhoneWork"
                            onChange={handleChange}
                        />
                    </FloatingLabel>
                </Row>

                <Row className="mb-3">
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Email"
                        as={Col}
                    >
                        <Form.Control
                            type="email"
                            placeholder="Email"
                            value={inputs.Email}
                            name="Email"
                            onChange={handleChange}
                        />
                    </FloatingLabel>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Startdatum"
                        as={Col}
                    >
                        <Form.Control
                            type="date"
                            name="StartDate"
                            placeholder="Startdatum"
                            value={inputs.StartDate}
                            onChange={handleChange}
                        />
                    </FloatingLabel>
                </Row>
                <Row>
                    <Col>
                        <hr />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Label>Taggning</Form.Label>
                        <Multiselect
                            options={availableTaggings}
                            isObject={false}
                            selectedValues={tagging} // Preselected value to persist in dropdown
                            onSelect={(list, item) => {
                                setTagging(list);
                            }}
                            onRemove={(list, item) => {
                                setTagging(list);
                            }}
                        />
                    </Col>
                    <Form.Group as={Col} className="mb-3" controlId="Taggning">
                        <Form.Label>Kommentar</Form.Label>
                        <Form.Control
                            ref={formTextareaRef}
                            as="textarea"
                            rows={5}
                            placeholder="Kommentar"
                            value={inputs.Description}
                            name="Description"
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Row>

                <Row>
                    <Col>
                        <Button variant="primary" type="submit">
                            Spara
                        </Button>
                    </Col>
                    <Col>
                        <Button 
                            variant="secondary"
                            onClick={() => navigate("/members")}
                            className="float-end"
                        >
                            Avbryt
                        </Button>
                    </Col>
                </Row>

            </Form>
        </Container>
    );
};
  
export default AddMember;