/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { Container, Button, Form, Col, FloatingLabel } from "react-bootstrap";
import { useState, useRef, useContext, useEffect } from "react";
import { store } from "../services/store";


const AddEditCar = (props) => {
    const formRef = useRef(null);
    const [inputCar, setInputCar]  = useState({});
    const globalStore = useContext(store);

	useEffect(() => {
        if(globalStore.state.editCar) {
            setInputCar(globalStore.state.editCar.obj);
        }
    }, []);

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value ? event.target.value : "";
        setInputCar(values => ({...values, [name]: value}))
    }

    const handleCheckChange = (event) => {
        const name = event.target.name;
        const value = event.target.checked ? 1 : 0;
        setInputCar(values => ({...values, [name]: value}))
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if(globalStore.state.editCar) {
            props.saveCar(inputCar, globalStore.state.editCar.index);
        } else {
            props.saveCar(inputCar);
        }
    }

    return (
        <Container>
            {}
            <Form onSubmit={handleSubmit} ref={formRef}>
                <FloatingLabel
                    controlId="floatingInput"
                    label="Märke"
                    as={Col}
                    style={{margin: "10px 0"}}
                >
                    <Form.Control
                        type="text"
                        name="Brand"
                        value={inputCar.Brand}
                        onChange={handleChange}
                        autoFocus={true}
                    />
                </FloatingLabel>
            
                <FloatingLabel
                    controlId="floatingInput"
                    label="Modell"
                    as={Col}
                    style={{margin: "10px 0"}}
                >
                    <Form.Control
                        type="text"
                        name="Model"
                        value={inputCar.Model}
                        onChange={handleChange}
                    />
                </FloatingLabel>
                <FloatingLabel
                    controlId="floatingInput"
                    label="Årsmodell"
                    as={Col}
                    style={{margin: "10px 0"}}
                >
                    <Form.Control
                        type="number"
                        name="BuiltYear"
                        value={inputCar.BuiltYear}
                        onChange={handleChange}
                    />
                </FloatingLabel>

                <FloatingLabel
                    controlId="floatingInput"
                    label="Reg nr"
                    as={Col}
                    style={{margin: "10px 0"}}
                >
                    <Form.Control
                        type="text"
                        name="RegNr"
                        value={inputCar.RegNr}
                        onChange={handleChange}
                    />
                </FloatingLabel>

                <FloatingLabel
                    controlId="floatingInput"
                    label="Chassi nr"
                    as={Col}
                    style={{margin: "10px 0"}}
                >
                    <Form.Control
                        type="text"
                        name="ChassiNr"
                        value={inputCar.ChassiNr}
                        onChange={handleChange}
                    />
                </FloatingLabel>

                <Form.Check 
                    type="checkbox"
                    name="Insurance"
                    id="Insurance"
                    value={inputCar.Insurance}
                    label="Försäkrad"
                    checked={inputCar.Insurance}
                    onChange={handleCheckChange}
                />

                <FloatingLabel
                    controlId="floatingInput"
                    label="Försäkringsdatum"
                    as={Col}
                    style={{margin: "10px 0"}}
                >
                    <Form.Control
                        type="date"
                        name="InsuranceDate"
                        value={inputCar.InsuranceDate}
                        onChange={handleChange}
                    />
                </FloatingLabel>

                <Button variant="primary" type="submit">
                    Spara
                </Button>
                <Button 
                    variant="secondary"
                    onClick={() => {
                        if (window.confirm("Är du säker på att ta bort bilen.")) {
                            props.deleteCar(globalStore.state.editCar.index);
                        }
                    }}
                    className="float-end"
                >
                    Ta bort
                </Button>
            </Form>                        
        </Container>
    );
};

export default AddEditCar;
