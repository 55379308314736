import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Members from "./pages/Members";
import Member from "./pages/Member";
import AddMember from "./components/AddMember";
import Login from "./pages/Login";
import NoPage from "./pages/NoPage";
import {StateProvider} from "./services/store";
import PrintLabels from "./pages/PrintLabels";
import ListMembers from "./pages/ListMembers";
import ListCars from "./pages/ListCars";
import PostnordListMembers from "./pages/PostnordListMembers";

import "bootstrap/dist/css/bootstrap.min.css";
import './Styles/index.css';
import "bootstrap-icons/font/bootstrap-icons.css"

function App() {
  return (
    <StateProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Login />} />
            <Route path="members" element={<Members />} />
            <Route path="member" >
              <Route path=":userid" element={<Member />} />
              <Route path="add" element={<AddMember />} />
            </Route>
            <Route path="/printlabels" element={<PrintLabels />} />
            <Route path="listmembers" element={<ListMembers />} />
            <Route path="listcars" element={<ListCars />} />
            <Route path="postnordlistmembers" element={<PostnordListMembers />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </StateProvider>
  );
}

export default App;
