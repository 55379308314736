/* eslint-disable react-hooks/exhaustive-deps */
import { store } from "../services/store";
import { useContext, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import "../Styles/print.css";

const PrintLabels = () => {
    const globalStore = useContext(store);
    const navigate = useNavigate();

	useEffect(() => {
        window.print();
        navigate("/members");
    }, []);

    const renderMemberCard = (member, index) => {
        return (
            <div className="printcard" key={index}>
                <div className="printbox">
                    <div className="address">
                        <div className="addressName">{member.FirstName} {member.LastName}</div>
                        <div>{member.Address}</div>
                        <div>{member.Zip} {member.City}</div>
                    </div>
                </div>
            </div>
        );
    }


    const renderCards = () => {
        if(globalStore.state.members) {
            return globalStore.state.members.map(renderMemberCard);
        }
    }

    return (
        <div className="page">
            <div>
                {renderCards()}
            </div>

        </div>
    );
};
  
export default PrintLabels;
