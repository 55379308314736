/* eslint-disable react-hooks/exhaustive-deps */
import { Outlet, Link } from "react-router-dom";
import { Container, Nav, Navbar } from "react-bootstrap";
import { useContext, useEffect } from "react";
import { store } from "../services/store";
import { logout, getUserRole } from "../services/firebase";
import { useNavigate } from 'react-router-dom';
import {roleValues} from "../services/appFunctions";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const Layout = () => {
	const globalStore = useContext(store);
    const { dispatch } = globalStore;
    const navigate = useNavigate();

	useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                getUserRole(user.uid).then(usrRole => {
                    const action = { type: 'SET_AUTHUSER', value: user, roleVal: roleValues.indexOf(usrRole) };
                    dispatch(action);
                });
            }
        });
	}, []);


	const doLogout = () => {
		logout();
		const action = { type: 'SET_AUTHUSER', value: undefined, roleVal: 0 };
		dispatch(action);
		navigate('/');
	}

	const renderLoginNav = () => {
		if(globalStore.state.roleValue >= 1) {
			return (
				<>
					<Nav.Link as={Link} to="/members">
						Hantera medlemmar
					</Nav.Link>
					<Navbar.Text>
						| {globalStore.state.authUser.email}
					</Navbar.Text>
					<Navbar.Text>
						&nbsp;| <i className="bi bi-x-octagon logoutIcon" onClick={doLogout} title="Logga ut"></i>
					</Navbar.Text>
				</>
			);
		} else {
			return (
				<Nav.Link as={Link} to="/login">
					Login
				</Nav.Link>
			);
		}
	}

	return (
		<>
			<Navbar
				collapseOnSelect
				expand="lg"
				bg={globalStore.state.theme}
				variant={globalStore.state.theme}
			>
				<Container>
					<Navbar.Brand as={Link} to="/">
						<img src="./logo.png" alt="Club69 logo" />
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="responsive-navbar-nav" />
					<Navbar.Collapse id="responsive-navbar-nav">
						<Nav>
							{renderLoginNav()}
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
			<Outlet />
		</>
	);
};

export default Layout;
