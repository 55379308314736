import { Container, Card, Table, Navbar, Nav} from "react-bootstrap";
import { store } from "../services/store";
import { useContext } from "react";
import { useNavigate } from 'react-router-dom';
import FilterMembers from "../components/FilterMembers";
import { Link } from "react-router-dom";

const ListCars = () => {
    const globalStore = useContext(store);
    const navigate = useNavigate();

    const renderRow = (listCarItem, index) => {
        return (
            <tr key={index}>
                <td onClick={() => navigate(`/member/${listCarItem.OwnerId}`)} style={{cursor: "pointer"}}>
                    {listCarItem.OwnerName}
                </td>
                <td>{listCarItem.Brand}</td>
                <td>{listCarItem.Model}</td>
                <td>{listCarItem.BuiltYear}</td>
                <td>{listCarItem.RegNr}</td>
                <td>{listCarItem.ChassiNr}</td>
                <td>{listCarItem.Insurance === 1 ? "Ja" : ""}</td>
                <td>{listCarItem.InsuranceDate}</td>
            </tr>
        );
    }
    
    const renderBody = () => {
        if(globalStore.state.members) {
            let listCars = [];
            globalStore.state.members.forEach(membr => {
                if(membr.Cars) {
                    membr.Cars.forEach(c => {
                        let carItem = {
                            OwnerId: membr.id,
                            OwnerName: membr.FirstName + " " + membr.LastName,
                            Brand: c.Brand,
                            Model: c.Model,
                            BuiltYear: c.BuiltYear,
                            RegNr: c.RegNr,
                            ChassiNr: c.ChassiNr,
                            Insurance: c.Insurance,
                            InsuranceDate: c.InsuranceDate
                        }
                        listCars.push(carItem);
                    });
                }
            });
            return listCars.map(renderRow);
        }
    }

    return (
        <>
            <Navbar 
				bg="primary" 
				variant="dark"
				style={{marginBottom: "20px"}}
			>
				<Navbar.Brand as={Link} to="/members">
					Hantera medlemmar
				</Navbar.Brand>
				<Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
					</Nav>

                    <FilterMembers />
				</Navbar.Collapse>
            </Navbar>
        
            <Container fluid>
                <Card style={{ padding: "20px", margin: "80px" }}>
                    <Card.Header>
                        <Card.Title>Club69 medlemmars bilar</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Table striped bordered>
                            <thead>
                                <th>Namn</th>
                                <th>Märke</th>
                                <th>Modell</th>
                                <th>Årsmodell</th>
                                <th>Reg nr</th>
                                <th>Chassi nr</th>
                                <th>Försäkrad</th>
                                <th>Försäkringsdatum</th>
                            </thead>
                            <tbody>
                                {renderBody()}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </Container>
        </>
    );
};
  
export default ListCars;
