/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { Container, Button, Form, Col, FloatingLabel } from "react-bootstrap";
import { useState, useRef } from "react";


const AddPayments = (props) => {
    const formRef = useRef(null);
    const [inputPay, setInputPay]  = useState({
        PayYear: (new Date().toISOString().substring(0, 4)),
        PayDate: (new Date().toISOString().substring(0, 10)),
        PayAmount: 100
    });


    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value ? event.target.value : "";
        setInputPay(values => ({...values, [name]: value}))
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        props.savePayment(inputPay);
    }

    return (
        <Container>
            <Form onSubmit={handleSubmit} ref={formRef}>
                <FloatingLabel
                    controlId="floatingInput"
                    label="Betalt gäller år"
                    as={Col}
                    style={{margin: "10px 0"}}
                >
                    <Form.Control
                        type="number"
                        name="PayYear"
                        value={inputPay.PayYear}
                        onChange={handleChange}
                        autoFocus={true}
                    />
                </FloatingLabel>
            
                <FloatingLabel
                    controlId="floatingInput"
                    label="Betalt datum"
                    as={Col}
                    style={{margin: "10px 0"}}
                >
                    <Form.Control
                        type="date"
                        name="PayDate"
                        value={inputPay.PayDate}
                        onChange={handleChange}
                    />
                </FloatingLabel>
                <FloatingLabel
                    controlId="floatingInput"
                    label="Betalt belopp"
                    as={Col}
                    style={{margin: "10px 0"}}
                >
                    <Form.Control
                        type="number"
                        name="PayAmount"
                        value={inputPay.PayAmount}
                        onChange={handleChange}
                    />
                </FloatingLabel>

                <Button variant="primary" type="submit">
                    Spara
                </Button>
            </Form>                        
        </Container>
    );
};

export default AddPayments;
