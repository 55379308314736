import { initializeApp } from "firebase/app"
import { getFirestore, collection, getDocs, addDoc, deleteDoc, doc, updateDoc, getDoc } from 'firebase/firestore';
import { getAuth, signInWithEmailAndPassword, signOut, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import {roleValues} from "./appFunctions";
import { sortItems } from "../services/appFunctions";

const config = {
    apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
    appId: process.env.REACT_APP_FIREBASE_APPID,
    authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
    projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID
}

const app = initializeApp(config);
export const firestoreDB = getFirestore(app);

export async function getMembers() {
    const membersCol = collection(firestoreDB, 'Members');
    const membersSnapshot = await getDocs(membersCol);
    const membersList = membersSnapshot.docs.map(doc => doc.data());
    console.log(membersList);
    let sortedMembersList = sortItems(membersList, "LastName", true);
    console.log(sortedMembersList);
    return sortedMembersList;
}

// export async function getUsers() {
//     const usersCol = collection(firestoreDB, 'Users');
//     const usersSnapshot = await getDocs(usersCol);
//     const usersList = usersSnapshot.docs.map(doc => doc.data());
//     return usersList;
// }

export async function getUserRole(userId) {
    const docRef = doc(firestoreDB, "Users", userId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        return(docSnap.data().role);
    } else {
        return(roleValues[0]);
    }
}

export async function loginUser(usrEmail, passw) {
    const auth = getAuth();
    const email = usrEmail;
    const password = passw;
    let userCredential = await signInWithEmailAndPassword(auth, email, password);
    return userCredential ? userCredential : undefined;
}

export function logout() {
    const auth = getAuth();
    signOut(auth);
}

export async function signInWithGoogle() {
    const auth = getAuth();
    const googleProvider = new GoogleAuthProvider();
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    return user;
};

export async function addNews(newsObj) {
    const docRef = await addDoc(collection(firestoreDB, "News"), newsObj);
    return docRef;
}

export async function saveNews(newsObj) {
    const docRef = doc(firestoreDB, "News", newsObj.id);
    await updateDoc(docRef, newsObj);
    return docRef;
}

export async function delNews(newsObj) {
    const docRef = await deleteDoc(doc(firestoreDB, "News", newsObj.id));
    return docRef;
}

export async function addMember(memberObj) {
    const docRef = await addDoc(collection(firestoreDB, "Members"), memberObj);
    return docRef;
}

export async function saveMember(memberObj) {
    const docRef = doc(firestoreDB, "Members", memberObj.id);
    await updateDoc(docRef, memberObj);
    return docRef;
}

export async function delMember(memberObj) {
    const docRef = await deleteDoc(doc(firestoreDB, "Members", memberObj.id));
    return docRef;
}
