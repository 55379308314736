import { Form, Button, Container, Card } from "react-bootstrap";
import { useState, useContext } from "react";
import { store } from "../services/store";
import { loginUser, getUserRole, signInWithGoogle } from "../services/firebase";
import { useNavigate } from 'react-router-dom';
import {roleValues} from "../services/appFunctions";

const Login = () => {
    const [inputs, setInputs] = useState({});
    const globalStore = useContext(store);
    const { dispatch } = globalStore;
    const navigate = useNavigate();

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value ? event.target.value : "";
        setInputs(values => ({...values, [name]: value}))
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        loginUser(inputs.email, inputs.password).then(loggedinUser => {
            getUserRole(loggedinUser.user.uid).then(usrRole => {
                const action = { type: 'SET_AUTHUSER', value: loggedinUser.user, roleVal: roleValues.indexOf(usrRole) };
                dispatch(action);
                navigate('/members');
            });
        });
    }

    const renderLoggedinUser = () => {
        if(globalStore.state.authUser !== undefined) {
            return (
                <h1>Your logged in as {globalStore.state.authUser.email}</h1>
            );
        } else {
            return (
                <h1>Login</h1>
            );
        }
    }

    return (
        <Container>
            <Card style={{ padding: "20px", margin: "80px" }}>
                <Card.Title>{renderLoggedinUser()}</Card.Title>

                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control 
                            type="email" 
                            placeholder="Enter email" 
                            value={inputs.email} 
                            name="email"
                            onChange={handleChange}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control 
                            type="password" 
                            placeholder="Password"
                            value={inputs.password} 
                            name="password"
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
                </Form>
                <Button 
                    variant="primary" 
                    className="loginGoogleBtn" 
                    onClick={() => {
                        signInWithGoogle().then(usr => {
                            navigate('/members');
                        });
                    }}>
                    Logga in med Google
                </Button>
            </Card>
        </Container>
    );
};

export default Login;
