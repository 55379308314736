/* eslint-disable react-hooks/exhaustive-deps */
import { Link } from "react-router-dom";
import { Container, Nav, Navbar, Row, Card, Col, OverlayTrigger, Tooltip, Modal, Form, Button } from "react-bootstrap";
import { firestoreDB } from "../services/firebase";
import { collection, onSnapshot, query } from 'firebase/firestore';
import { store } from "../services/store";
import { useContext, useEffect, useState } from "react";
import { sortItems } from "../services/appFunctions";
import { useNavigate } from 'react-router-dom';
import FilterMembers from "../components/FilterMembers";

const Members = () => {
    const globalStore = useContext(store);
    const { dispatch } = globalStore;
    const navigate = useNavigate();
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [emailSeparator, setEmailSeparator] = useState(";");

	useEffect(() => {
        const qMembers = query(collection(firestoreDB, "Members"));
        // eslint-disable-next-line no-unused-vars
        const unsubscribeMembers = onSnapshot(qMembers, (querySnapshot) => {
            const members = [];
            querySnapshot.forEach((doc) => {
                let m = doc.data();
                m.id = doc.id;
                members.push(m);
            });
            let sortedMembers = sortItems(members, "LastName");
            const action = { type: 'SET_ALLMEMBERS', value: sortedMembers };
            dispatch(action);
			clearFilter();
		});
	}, []);

	const clearFilter = () => {
		let updSearchFilter = {searchText: "", tags: [], payment: "Alla", hasEmail: ""};
		const action = { type: 'SET_SEARCHFILTER', value: updSearchFilter};
		dispatch(action);
	}

	const renderCarIcon = (member) => {
		if(member.Cars?.length > 0) {
			let nrCars = member.Cars.length;

			return (
				<OverlayTrigger
					placement="left"
					delay={{ show: 250, hide: 400 }}
					overlay={
						<Tooltip id="button-tooltip">
							{nrCars} bilar.
						</Tooltip>
					}
				>
					<i className="bi bi-file-ppt-fill" style={{fontSize: "2rem", color: "blue"}}></i>
				</OverlayTrigger>
			);
		} else {
			return false;
		}
	}

	const renderPayIcon = (member) => {
		if(member.Payments) {
			let payedThisYear = member.Payments.filter(p => p.PayYear === (new Date().toISOString().substring(0, 4)));
			if(payedThisYear && payedThisYear.length > 0) {
				return (
					<OverlayTrigger
						placement="left"
						delay={{ show: 250, hide: 400 }}
						overlay={
							<Tooltip id="button-tooltip">
								Betalt {payedThisYear[0].PayAmount} kr {payedThisYear[0].PayDate}
							</Tooltip>
						}
					>
						<i className="bi bi-credit-card-fill" style={{fontSize: "2rem", color: "green"}}></i>
					</OverlayTrigger>
				);
			} else {
				return (
					<OverlayTrigger
						placement="left"
						delay={{ show: 250, hide: 400 }}
						overlay={
							<Tooltip id="button-tooltip">
								Saknar betalning för {(new Date().toISOString().substring(0, 4))}
							</Tooltip>
						}
					>
						<i className="bi bi-credit-card-fill" style={{fontSize: "2rem", color: "red"}}></i>
					</OverlayTrigger>
				);
			}
		} else {
			return (
				<OverlayTrigger
					placement="left"
					delay={{ show: 250, hide: 400 }}
					overlay={
						<Tooltip id="button-tooltip">
							Saknar betalning för {(new Date().toISOString().substring(0, 4))}
						</Tooltip>
					}
				>
					<i className="bi bi-credit-card-fill" style={{fontSize: "2rem", color: "red"}}></i>
				</OverlayTrigger>
			);
		}
	}

	const renderEmailIcon = (member) => {
		if(member.Email) {
			let selectedIndex = globalStore.state.selectedEmail.indexOf(member.Email); 
			let iconName = selectedIndex !== -1 ? "bi bi-envelope-check-fill pointer" : "bi bi-envelope-fill pointer"

			return (
				<OverlayTrigger
					placement="left"
					delay={{ show: 250, hide: 400 }}
					overlay={
						<Tooltip id="button-tooltip">
							Markera eller avmarkera email
						</Tooltip>
					}
				>
					<i 
						className={iconName}
						style={{fontSize: "2rem", color: selectedIndex !== -1 ? "blue" : "gray"}}
						onClick={() => {
							let updSelEmails = globalStore.state.selectedEmail;
							if(updSelEmails.indexOf(member.Email) !== -1) {
								updSelEmails.splice(updSelEmails.indexOf(member.Email), 1);
							} else {
								updSelEmails.push(member.Email);
							}
							const action = { type: 'SET_SELECTEDEMAIL', value: updSelEmails };
							dispatch(action);
						}}
					></i>
				</OverlayTrigger>
			);
		}
	}


	const renderMemberCard = (member, index) => {
		return (
            <Col key={index}>
                <Card
                    bg="light"
                    text="dark"
                >
					<Row>
						<Col>
							<Card.Body>
								<Card.Title onClick={() => navigate(`/member/${member.id}`)} style={{cursor: "pointer"}}>
									{member.FirstName} {member.LastName}
								</Card.Title>
								<Card.Text>
									{member.Address}
								</Card.Text>
								<Card.Text>
									{member.Zip} {member.City}
								</Card.Text>
								<Card.Text>
									<a href={`mailto:${member.Email}`}>{member.Email}</a>
								</Card.Text>
							</Card.Body>
						</Col>
						<Col xs lg="2">
							<Card.Body>
								<Card.Text>
									{renderCarIcon(member)}
								</Card.Text>
								<Card.Text>
									{renderPayIcon(member)}
								</Card.Text>
								<Card.Text>
									{renderEmailIcon(member)}
								</Card.Text>
							</Card.Body>
						</Col>
					</Row>
                </Card>
            </Col>
        );
	}

    const renderMemberCards = () => {
        if(globalStore.state.members) {
            return globalStore.state.members.map(renderMemberCard);
        }
    }

	return (
		<>
			<Navbar 
				bg="primary" 
				variant="dark"
				style={{marginBottom: "20px"}}
			>
				<Navbar.Brand as={Link} to="/members">
					Hantera medlemmar
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="responsive-navbar-nav" />
				<Navbar.Collapse id="responsive-navbar-nav">
					<Nav className="me-auto">
						{globalStore.state.roleValue >= 2 && 
							<Nav.Item>
								<Nav.Link as={Link} to="/member/add">
									Skapa ny medlem
								</Nav.Link>
							</Nav.Item>
						}
						<Nav.Item>
							<Nav.Link onClick={() => setShowEmailModal(true)}>
								Email
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link as={Link} to="/printlabels">
								Utskrift
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link as={Link} to="/postnordlistmembers">
								Postnord addresslista
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link as={Link} to="/listmembers">
								Lista medlemmar
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link as={Link} to="/listcars">
								Lista bilar
							</Nav.Link>
						</Nav.Item>
					</Nav>

					<FilterMembers />

				</Navbar.Collapse>
			</Navbar>
			<Container fluid>
	            <Row xs={1} md={3} className="g-4">
    	            {renderMemberCards()}
        	    </Row>
			</Container>

			<Modal 
                show={showEmailModal} 
                onHide={() => {setShowEmailModal(false)}}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Email</Modal.Title>
                </Modal.Header>
                <Modal.Body>
					<Row>
						<Col>
							<label>Separator</label>
							<select value={emailSeparator} onChange={event => setEmailSeparator(event.target.value)}>
								<option>;</option>
								<option>,</option>
							</select>
						</Col>
						<Col>
							<Button 
								variant="secondary"
								onClick={() => {
									let allSelectedMail = [];
									globalStore.state.members.forEach(m => {
										if(m.Email !== undefined && m.Email !== "" && m.Email !== null) {
											allSelectedMail.push(m.Email);
										}
									});
									const action = { type: 'SET_SELECTEDEMAIL', value: allSelectedMail };
									dispatch(action);
								}}
							>
								Välj alla
							</Button>
						</Col>
						<Col>
							<Button 
								variant="secondary"
								onClick={() => {
									const action = { type: 'SET_SELECTEDEMAIL', value: [] };
									dispatch(action);
								}}
							>
								Avmarkera alla
							</Button>
						</Col>
					</Row>
					<Form.Control
						as="textarea"
						rows={5}
						defaultValue={globalStore.state.selectedEmail.join(emailSeparator)}
					/>
					<Row>
						<Col>
							Klicka på knappen för att öppna ditt e-post program. Använder du en webbläsare för att läsa e-post så fungerar inte knappen utan du måste då själv kopiera e-post adresserna och klistra in den i ett nytt meddelande.
						</Col>
					</Row>


					<Button 
						variant="secondary"
						onClick={() => {
							document.location.href = "mailto:?bcc=" + globalStore.state.selectedEmail.join(emailSeparator);
						}}
						className="float-end"
					>
						Öppna e-post program
					</Button>


                </Modal.Body>
            </Modal>

		</>
	);
};

export default Members;
