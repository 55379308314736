import { Container, Card, Table, Navbar, Nav} from "react-bootstrap";
import { store } from "../services/store";
import { useContext } from "react";
import FilterMembers from "../components/FilterMembers";
import { Link } from "react-router-dom";

const PostnordListMembers = () => {
    const globalStore = useContext(store);

    const renderMemberRow = (member, index) => {
        return (
            <tr key={index}>
                <td>{member.FirstName} {member.LastName}</td>
                <td>{member.Address}</td>
                <td></td>
                <td>{member.Zip.replace(/ +/g, "")}</td>
                <td>{member.City}</td>
                <td>Sverige</td>
                <td>SE</td>
            </tr>
        );
    }
    
    const renderMembersBody = () => {
        if(globalStore.state.members) {
            return globalStore.state.members.map(renderMemberRow);
        }
    }

    return (
        <>
            <Navbar 
				bg="primary" 
				variant="dark"
				style={{marginBottom: "20px"}}
			>
				<Navbar.Brand as={Link} to="/members">
					Hantera medlemmar
				</Navbar.Brand>
				<Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
					</Nav>

                    <FilterMembers />
				</Navbar.Collapse>
            </Navbar>

        
            <Container fluid>
                <Card style={{ padding: "20px", margin: "80px" }}>
                    <Card.Header>
                        <Card.Title>Postnord utskick</Card.Title>
                        <ol>
                            <li>
                                Kopiera denna tabell till en Excel fil (.xls eller .xlsx)
                            </li>
                            <li>
                                Ladda upp filen till <a href="https://portal.postnord.com/skapa-utskick/v2" rel="noreferrer" target="_blank">Postnord</a> för utskick.
                            </li>
                        </ol>
                    </Card.Header>
                    <Card.Body>

                        <Table striped bordered>
                            <thead>
                                <th>Namn</th>
                                <th>Adressrad 2 (Frivilligt)</th>
                                <th>Adressrad 3 (Frivilligt)</th>
                                <th>Postnummer</th>
                                <th>Ort</th>
                                <th>Land</th>
                                <th>Landskod (ISO 2 bokstäver)</th>
                            </thead>
                            <tbody>
                                {renderMembersBody()}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </Container>
        </>

    );
};
  
export default PostnordListMembers;
