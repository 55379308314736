
export const availableTaggings = ["Styrelsemedlem", "Årsmötesvald 2022"];

export const roleValues = ["visitor", "editor", "admin"];

export const sortItems = (items, sortBy, descending = false) => {
    if (descending) {
        return items.sort((a, b) => {
            if (a[sortBy] < b[sortBy]) {
                return 1;
            }
            if (a[sortBy] > b[sortBy]) {
                return -1;
            }
            return 0;
        });
    } else {
        return items.sort((a, b) => {
            if (a[sortBy] < b[sortBy]) {
                return -1;
            }
            if (a[sortBy] > b[sortBy]) {
                return 1;
            }
            return 0;
        });
    }
}