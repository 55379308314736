/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useParams } from "react-router-dom";
import { Container, Card, Button, Row, Col, Form, FloatingLabel, Alert, Modal, Table} from "react-bootstrap";
import { firestoreDB, saveMember, delMember } from "../services/firebase";
import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState, useRef, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { store } from "../services/store";
import { Multiselect } from 'multiselect-react-dropdown';
import AddPayments from "../components/AddPayments";
import { sortItems, availableTaggings } from "../services/appFunctions";
import AddEditCar from "../components/AddEditCar";

const Member = () => {
    const { userid } = useParams();
    const [selectedUser, setSelectedUser]  = useState(); 
    const navigate = useNavigate();
    const formRef = useRef(null);
    const formTextareaRef = useRef(null);
    const [tagging, setTagging] = useState([]);
    const globalStore = useContext(store);
    const { dispatch } = globalStore;
    const [showDoneSave, setShowDoneSave]  = useState(false); 
    const [showPayModal, setShowPayModal] = useState(false);
    const [showCarModal, setShowCarModal] = useState(false);

    
	useEffect(() => {
        const docRef = doc(firestoreDB, "Members", userid);
        getDoc(docRef).then(docSnap => {
            if (docSnap.exists()) {
                let usr = docSnap.data();
                usr.id = docSnap.id;
                setSelectedUser(usr);
                let usrTags = usr.Tagging ? usr.Tagging : [];
                setTagging(usrTags);
            } else {
                setSelectedUser(undefined);
                setTagging([]);
            }
        });
	}, []);

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value ? event.target.value : "";
        setSelectedUser(values => ({...values, [name]: value}))
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        let updMemberObj = selectedUser;
        const today = new Date();
        updMemberObj.Modified = today.toISOString();
        updMemberObj.ModifiedBy = globalStore.state.authUser.email;
        updMemberObj.Tagging = tagging;
        saveMember(updMemberObj).then(() => {
            setShowDoneSave(true);
            setTimeout(() => {
                setShowDoneSave(false);
            }, 3000);
        });
    }

    const handleSavePayment = (newPayment, savePaymentIndex) => {
        let updMemberObj = selectedUser;
        const today = new Date();
        updMemberObj.Modified = today.toISOString();
        updMemberObj.ModifiedBy = globalStore.state.authUser.email;
        if(updMemberObj.Payments) {
            if (savePaymentIndex !== undefined) {
                updMemberObj.Payments[savePaymentIndex] = newPayment;
            } else {
                updMemberObj.Payments = [...updMemberObj.Payments, newPayment];
            }
        } else {
            updMemberObj.Payments = [newPayment];
        }
        saveMember(updMemberObj).then(() => {
            setShowPayModal(false);
        });
    }

    const handleDeletePayment = (delPaymentIndex) => {
        let updMemberObj = selectedUser;
        const today = new Date();
        updMemberObj.Modified = today.toISOString();
        updMemberObj.ModifiedBy = globalStore.state.authUser.email;
        updMemberObj.Payments.splice(delPaymentIndex, 1);
        saveMember(updMemberObj).then(() => {
            setShowPayModal(false);
        });
    }

    const handleSaveCar = (saveCarObj, saveCarIndex) => {
        let updMemberObj = selectedUser;
        const today = new Date();
        updMemberObj.Modified = today.toISOString();
        updMemberObj.ModifiedBy = globalStore.state.authUser.email;
        if(updMemberObj.Cars) {
            if (saveCarIndex !== undefined) {
                updMemberObj.Cars[saveCarIndex] = saveCarObj;
            } else {
                updMemberObj.Cars = [...updMemberObj.Cars, saveCarObj];
            }
        } else {
            updMemberObj.Cars = [saveCarObj];
        }
        saveMember(updMemberObj).then(() => {
            setShowCarModal(false);
        });
    }

    const handleDeleteCar = (delCarIndex) => {
        let updMemberObj = selectedUser;
        const today = new Date();
        updMemberObj.Modified = today.toISOString();
        updMemberObj.ModifiedBy = globalStore.state.authUser.email;
        updMemberObj.Cars.splice(delCarIndex, 1);
        saveMember(updMemberObj).then(() => {
            setShowCarModal(false);
        });
    }

    const renderMemberCard = () => {
        if(selectedUser) {
            return (
                <Card>
                    <Form onSubmit={handleSubmit} ref={formRef}>
                        <Card.Header>
                            <Card.Title>{selectedUser.FirstName} {selectedUser.LastName}</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Row className="mb-3">
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Förnamn"
                                    as={Col}
                                >
                                    <Form.Control
                                        type="text"
                                        placeholder="FirstName"
                                        value={selectedUser.FirstName}
                                        name="FirstName"
                                        onChange={handleChange}
                                    />
                                </FloatingLabel>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Efternamn"
                                    as={Col}
                                >
                                    <Form.Control
                                        type="text"
                                        placeholder="Efternamn"
                                        value={selectedUser.LastName}
                                        name="LastName"
                                        onChange={handleChange}
                                    />
                                </FloatingLabel>
                            </Row>
                            <Row className="mb-3">
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Adress"
                                    as={Col}
                                >
                                    <Form.Control
                                        type="text"
                                        placeholder="Adress"
                                        value={selectedUser.Address}
                                        name="Address"
                                        onChange={handleChange}
                                    />
                                </FloatingLabel>

                            </Row>
                            <Row className="mb-3">
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Postnummer"
                                    as={Col}
                                >
                                    <Form.Control
                                        type="text"
                                        placeholder="Postnummer"
                                        value={selectedUser.Zip}
                                        name="Zip"
                                        onChange={handleChange}
                                    />
                                </FloatingLabel>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Ort"
                                    as={Col}
                                >
                                    <Form.Control
                                        type="text"
                                        placeholder="Ort"
                                        value={selectedUser.City}
                                        name="City"
                                        onChange={handleChange}
                                    />
                                </FloatingLabel>
                            </Row>
                            <Row className="mb-3">
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Telefonnummer (Hem)"
                                    as={Col}
                                >
                                    <Form.Control
                                        type="text"
                                        placeholder="Hem"
                                        value={selectedUser.PhoneHome}
                                        name="PhoneHome"
                                        onChange={handleChange}
                                    />
                                </FloatingLabel>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Telefonnummer (Mobil)"
                                    as={Col}
                                >
                                    <Form.Control
                                        type="text"
                                        placeholder="Mobil"
                                        value={selectedUser.PhoneMobil}
                                        name="PhoneMobil"
                                        onChange={handleChange}
                                    />
                                </FloatingLabel>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Telefonnummer (Arbete)"
                                    as={Col}
                                >
                                    <Form.Control
                                        type="text"
                                        placeholder="Arbete"
                                        value={selectedUser.PhoneWork}
                                        name="PhoneWork"
                                        onChange={handleChange}
                                    />
                                </FloatingLabel>
                            </Row>

                            <Row className="mb-3">
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Email"
                                    as={Col}
                                >
                                    <Form.Control
                                        type="email"
                                        placeholder="Email"
                                        value={selectedUser.Email}
                                        name="Email"
                                        onChange={handleChange}
                                    />
                                </FloatingLabel>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Startdatum"
                                    as={Col}
                                >
                                    <Form.Control
                                        type="date"
                                        name="StartDate"
                                        placeholder="Startdatum"
                                        value={selectedUser.StartDate}
                                        onChange={handleChange}
                                    />
                                </FloatingLabel>
                            </Row>
                            <Row>
                                <Col>
                                    <hr />
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <Form.Label>Taggning</Form.Label>
                                    <Multiselect
                                        options={availableTaggings}
                                        name="Tagging"
                                        isObject={false}
                                        selectedValues={tagging}
                                        onSelect={(list, item) => {
                                            setTagging(list);
                                        }}
                                        onRemove={(list, item) => {
                                            setTagging(list);
                                        }}
                                    />
                                </Col>
                                <Form.Group as={Col} className="mb-3" controlId="Taggning">
                                    <Form.Label>Kommentar</Form.Label>
                                    <Form.Control
                                        ref={formTextareaRef}
                                        as="textarea"
                                        rows={5}
                                        placeholder="Kommentar"
                                        value={selectedUser.Description}
                                        name="Description"
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Row>
                        </Card.Body>
                        <Card.Footer>
                            <Row>
                                {globalStore.state.roleValue >= 2 && 
                                    <Col>
                                        <Button variant="primary" type="submit">
                                            Spara
                                        </Button>
                                    </Col>
                                }
                                {globalStore.state.roleValue >= 2 && 
                                    <Col>
                                        <Button 
                                            variant="secondary"
                                            onClick={() => {
                                                if (window.confirm("Är du säker på att avsluta medlemskap för " + selectedUser.FirstName + " " + selectedUser.LastName)) {
                                                    //some code
                                                    delMember(selectedUser).then(() => {
                                                        navigate("/members");
                                                    })
                                                }
                                            }}
                                            className="float-end"
                                        >
                                            Avsluta medlemskap
                                        </Button>
                                    </Col>
                                }
                                <Col>
                                    <Button 
                                        variant="secondary"
                                        onClick={() => navigate("/members")}
                                        className="float-end"
                                    >
                                        Back
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Alert variant="success" show={showDoneSave}> 
                                    Det gick bra att spara du kan backa till medlemslistan nu.
                                </Alert>
                            </Row>
                        </Card.Footer>
                    </Form>
                </Card>
            );
        } else {
            return (
                <Card>
                    <Card.Header>
                        <Card.Title>Invalid memberid</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        This is not a valid memberid
                    </Card.Body>
                </Card>
            );
        }
    }

    const renderPayment = (payItem, index) => {
        return (
            <tr key={index}>
                <td>{payItem.PayYear}</td>
                <td>{payItem.PayAmount}</td>
                <td>{payItem.PayDate}</td>
                <td>
                    <i className="bi bi-trash pointer"
                        onClick={() => {
                            if (window.confirm("Är du säker på att ta bort inbetalningen.")) {
                                handleDeletePayment(index);
                            }
                        }}
                    >
                    </i>
                </td>
            </tr>
        );
    }

    const renderPayments = () => {
        if(selectedUser.Payments) {
            return (
                <Table striped bordered>
                    <thead>
                        <th>År</th>
                        <th>Betalt (kr)</th>
                        <th>Betalt datum</th>
                        <th>
                        </th>
                    </thead>
                    <tbody>
                        {sortItems(selectedUser.Payments, "PayYear", true).map(renderPayment)}
                    </tbody>
                </Table>
            );
        }
    }

    const renderPaymentCard = () => {
        if(selectedUser) {
            return (
                <Card>
                    <Card.Header>
                        <Card.Title>Inbetalningar</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        {globalStore.state.roleValue >= 2 && 
                            <Button 
                                variant="secondary"
                                onClick={() => setShowPayModal(true)}
                                className="float-end"
                            >
                                Registrera betalning
                            </Button>
                        }
                        {renderPayments()}
                    </Card.Body>
                </Card>
            ); 
            
        }
    }

    const renderCar = (carItem, index) => {
        return (
            <tr 
                key={index}
                onClick={() => {
                    let editCarObj = {index: index, obj: carItem};
                    const action = { type: 'SET_EDITCAR', value: editCarObj };
                    dispatch(action);
                    setShowCarModal(true);
                }}
                className="pointer"
            >
                <td>{carItem.Brand}</td>
                <td>{carItem.Model}</td>
                <td>{carItem.BuiltYear}</td>
                <td>{carItem.RegNr}</td>
                <td>{carItem.ChassiNr}</td>
                <td>{carItem.Insurance === 1 ? "Ja" : ""}</td>
                <td>{carItem.InsuranceDate}</td>
            </tr>
        );
    }

    const renderCars = () => {
        if(selectedUser.Cars) {
            return (
                <Table striped bordered>
                    <thead>
                        <th>Märker</th>
                        <th>Modell</th>
                        <th>Årsmodell</th>
                        <th>Reg nr</th>
                        <th>Chassi nr</th>
                        <th>Försäkrad</th>
                        <th>Försäkringsdatum</th>
                    </thead>
                    <tbody>
                        {selectedUser.Cars.map(renderCar)}
                    </tbody>
                </Table>
            );
        }
    }
    
    const renderCarsCard = () => {
        if(selectedUser) {
            return (
                <Card>
                    <Card.Header>
                        <Card.Title>Bilar</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Card.Text>
                            {globalStore.state.roleValue >= 2 && 
                                <Button 
                                    variant="secondary"
                                    onClick={() => {
                                        const action = { type: 'SET_EDITCAR', value: undefined };
                                        dispatch(action);
                                        setShowCarModal(true);
                                    }}
                                    className="float-end"
                                >
                                    Registrera ny bil
                                </Button>
                            }
                            </Card.Text>
                        <Card.Text>
                            {renderCars()}
                        </Card.Text>
                    </Card.Body>
                </Card>
            ); 
            
        }
    }

    return (
        <Container>
            <Row style={{margin: "10px"}}>
                {renderMemberCard()}
            </Row>
            <Row style={{margin: "10px"}}>
                <Col sm={12} md={12} lg={4}>
                    {renderPaymentCard()}
                </Col>
                <Col>
                    {renderCarsCard()}
                </Col>
            </Row>

            <Modal 
                show={showPayModal} 
                onHide={() => {setShowPayModal(false)}}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Inbetalning</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddPayments savePayment={handleSavePayment} deletePayment={handleDeletePayment} />
                </Modal.Body>
            </Modal>

            <Modal 
                show={showCarModal} 
                onHide={() => {setShowCarModal(false)}}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Bil</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddEditCar saveCar={handleSaveCar} deleteCar={handleDeleteCar} />
                </Modal.Body>
            </Modal>
        </Container>
    );
};

export default Member;