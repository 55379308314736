import { Container, Card, Table, Navbar, Nav} from "react-bootstrap";
import { store, currentYear } from "../services/store";
import { useContext } from "react";
import FilterMembers from "../components/FilterMembers";
import { Link } from "react-router-dom";

const ListMembers = () => {
    const globalStore = useContext(store);

    const renderMemberRow = (member, index) => {
        let payedThisYear = [];
        if(member.Payments !== undefined) {
            payedThisYear = member.Payments.filter(p => p.PayYear === currentYear)
        }
        let nrCars = 0;
        if(member.Cars !== undefined) {
			nrCars = member.Cars.length;
        }

        return (
            <tr key={index}>
                <td>{member.FirstName} {member.LastName}</td>
                <td>{member.Address}</td>
                <td>{member.Zip} {member.City}</td>
                <td>{member.Email}</td>
                <td>{nrCars}</td>
                <td>{payedThisYear.length > 0 ? "Ja" : ""}</td>
            </tr>
        );
    }
    
    const renderMembersBody = () => {
        if(globalStore.state.members) {
            return globalStore.state.members.map(renderMemberRow);
        }
    }

    return (
        <>
            <Navbar 
				bg="primary" 
				variant="dark"
				style={{marginBottom: "20px"}}
			>
				<Navbar.Brand as={Link} to="/members">
					Hantera medlemmar
				</Navbar.Brand>
				<Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
					</Nav>

                    <FilterMembers />
				</Navbar.Collapse>
            </Navbar>

        
            <Container fluid>
                <Card style={{ padding: "20px", margin: "80px" }}>
                    <Card.Header>
                        <Card.Title>Medlemmar club69</Card.Title>
                    </Card.Header>
                    <Card.Body>

                        <Table striped bordered>
                            <thead>
                                <th>Namn</th>
                                <th>Adress</th>
                                <th>Postnr ort</th>
                                <th>E-post</th>
                                <th>Antal bilar</th>
                                <th>Betalt årsavgift</th>
                            </thead>
                            <tbody>
                                {renderMembersBody()}
                            </tbody>
                        </Table>



                    </Card.Body>

                </Card>
            </Container>
        </>

    );
};
  
export default ListMembers;
